import InputField from "../../Components/Common/InputField/InputField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

const SignIn = () => {
  const navigate = useNavigate();
  const [phoneNumber, setphoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const handlephoneNumberChange = (e) => {
    setphoneNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = () => {
    const data = {
      phoneNumber: phoneNumber,
      password: password,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/users/user-login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("User login successful", response.data);
        toast.success("Log in Successful");
        localStorage.setItem("Token", `${response.data.token}`); //Stored the token in local storage

        localStorage.setItem(
          "User Details",
          JSON.stringify(response.data.user)
        ); //Stored the user details in local storage for future uses

        navigate("/admin/branches");
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        toast.error("Invalid Credentials");
      });
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <ToastContainer position="top-center" />
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="text-navy-700 mb-2.5 text-4xl font-bold dark:text-white">
          Admin Login
        </h4>
        <p className="mb-9 ml-1 text-base tracking-wide text-gray-600">
          Enter your registered Phone Number and Password
        </p>

        {/* Phone no */}
        <InputField
          variant="auth"
          extra="mb-6"
          label="Phone No.*"
          placeholder="Phone number"
          id="phoneNumber"
          type="number"
          value={phoneNumber}
          onChange={handlephoneNumberChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />

        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2"> */}
        {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
        {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        {/* </div> */}
        <button
          // onClick={() => {
          //
          //   navigate("/admin/dashboard");
          // }}
          onClick={handleSignIn}
          className="linear hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200 mt-2 w-full rounded-xl bg-[#fe8740] py-[12px] text-base font-medium text-white transition duration-200 dark:text-white"
        >
          Sign In
        </button>

        {/* <div className="mt-4">
          <span className=" text-navy-700 text-sm font-medium dark:text-gray-600">
            Forgot Password?
          </span>
          <a
            href=" "
            className="text-brand-500 hover:text-brand-600 ml-1 text-sm font-medium dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
};
export default SignIn;
