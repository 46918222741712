/* eslint-disable */

import { HiX } from "react-icons/hi";
import routes from "../../routes";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/img/logos/iberlogo.png";

const Sidebar = ({ open, onClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };
  const user = localStorage.getItem("User Details");
  const userObject = JSON.parse(user);
  const isSubAdmin = userObject?.userType === "sub-admin";

  return (
    <div
      className={`fixed left-0 top-0 z-50 h-full w-72 transform rounded-r-3xl bg-[#ffffff] p-4 font-medium tracking-wide text-gray-900 shadow-lg transition-transform ${
        open ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0`}
    >
      <div className="flex items-center justify-between rounded-lg bg-[#ffffff] p-4 relative">
        <div>
          <img src={logo} alt="logo" className="w-[80%] "/>
        </div>
        <button
          className="text-gray-300 hover:text-white focus:outline-none lg:hidden absolute top-0 right-0"
          onClick={onClose}
        >
          <HiX className="" color="orange" />
        </button>
      </div>

      <div className="mt-4 border-b border-[#fe8740]" />

      <nav className="mt-4 flex flex-col space-y-2">
        {/* {routes.map(
          (route, index) =>
            route.showInSidebar &&
            route.path !== "sign-in" && (!isSubAdmin || (isSubAdmin && route.showToSubAdmin)) ( */}
        {routes.map(
          (route, index) =>
            route.showInSidebar &&
            route.path !== "sign-in" &&
            (!isSubAdmin || (isSubAdmin && route.showToSubAdmin)) && (
              <div key={index} className="w-full">
                {!route.children ? (
                  <NavLink
                    to={`${route.layout}/${route.path}`}
                    className={({ isActive }) =>
                      `flex items-center rounded-lg px-6 py-2 transition-all duration-200 ${
                        isActive
                          ? "bg-[#fe8740] text-white "
                          : "text-[#fe8740] hover:bg-[#ed4609] hover:text-white"
                      }`
                    }
                  >
                    {route.icon}
                    <span className="ml-3">{route.name}</span>
                  </NavLink>
                ) : (
                  <div>
                    <button
                      onClick={() => toggleDropdown(route.name)}
                      className="flex w-full items-center justify-between rounded-lg bg-[#ffffff] px-6 py-2 text-[#fe8740] transition-all duration-200 hover:bg-[#ed4609] hover:text-white focus:outline-none"
                    >
                      <div className="flex items-center">
                        {route.icon}
                        <span className="ml-3">{route.name}</span>
                      </div>
                      <svg
                        className={`h-4 w-4 transform transition-transform ${
                          openDropdown === route.name ? "rotate-180" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        ></path>
                      </svg>
                    </button>
                    <div
                      className="ml-6 mt-2 overflow-hidden"
                      style={{
                        maxHeight: openDropdown === route.name ? "200px" : "0",
                        transition: "max-height 0.5s ease",
                      }}
                    >
                      {route.children.map((child, childIndex) => (
                        <NavLink
                          key={childIndex}
                          to={`${child.layout}/${child.path}`}
                          className={({ isActive }) =>
                            `mb-2 flex items-center rounded-lg px-6 py-2 transition-all duration-200 ${
                              isActive
                                ? "bg-[#fe8740] text-white "
                                : "text-[#fe8740] hover:bg-[#ed4609] hover:text-white"
                            }`
                          }
                        >
                          <span className="ml-3">{child.name}</span>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )
        )}
      </nav>

      <div className="flex-grow" />

      <div className="p-4">
        {/* Add any footer or additional content here */}
      </div>
    </div>
  );
};

export default Sidebar;
