import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OfferModal from "../Offer/OfferModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Loader from "Components/Common/Loader/Loader";

import axios from "axios";

function Offer() {
  const { id } = useParams(); //Branch ID fetched from URL
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [offers, setOffers] = useState([]);
  const navigate = useNavigate();

  //Function to open the Add New Offer modal
  const handleModalOpen = () => {
    setOpen(true);
  };

  //Function to close the Add New Offer modal
  const handleModalClose = () => {
    setOpen(false);
    showAllOffers();
  };

  const handleErrorToast = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      draggable: true,
      pauseOnHover: true,
    });
  };

  //Function to show all the offers on UI
  const showAllOffers = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setOffers(response.data);
        console.log(response.data);
        console.log(offers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  //Function to navigate to the edit offer page
  const handleEditClick = (offerID) => {
    navigate(`/admin/branches/branchDetails/editOffer/${offerID}`);
  };

  //Function to delete an offer
  const deleteOffer = (offerID) => {
    setLoading(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/deleteOffer/${offerID}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Offer deleted successfully");
        console.log(response.data);
        showAllOffers();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=> {
        setLoading(false);
      })
  };

  useEffect(() => {
    showAllOffers();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div className="flex items-end justify-end ">
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={handleModalOpen}
        >
          Add New Offer
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 xl:grid-cols-3">
        {offers?.map((offer, index) => (
          <div
            key={index}
            className="relative flex flex-col rounded-lg bg-white p-4 shadow-lg"
          >
            <div className="absolute right-2 top-2 flex gap-4 text-lg">
              <FaRegEdit
                className="cursor-pointer text-green-600"
                onClick={() => handleEditClick(offer._id)}
              />
              <MdDeleteOutline
                className="cursor-pointer text-red-800"
                onClick={() => deleteOffer(offer._id)}
              />
            </div>
            <h1 className="my-4 text-2xl font-bold tracking-wide text-gray-800">
              {offer.name}
            </h1>
            <h2 className="text-base font-semibold tracking-wide text-gray-500">
              {offer.description}
            </h2>
            {/* <p className="text-base font-semibold tracking-wide text-gray-500">
                  Manager's Name
                </p> */}
          </div>
        ))}
      </div>

      {open && (
        <OfferModal
          onClose={handleModalClose}
          onError={handleErrorToast}
          id={id}
        />
      )}
    </div>
  );
}

export default Offer;
