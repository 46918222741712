import React, { useState } from "react";
import { useRef } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function ServiceModal({ onClose, onError, id }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
    branch: id,
    price: "",
    image: "",
  });
  const [loading, setLoading] = useState(false);

  const [imageFile, setImageFile] = useState(null);

  // Function to handle the change in input fields
  const handleChange = (e) => {
    setServiceData({
      ...serviceData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    setImageFile(file);

    if (file) {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      // Upload the image to Cloudinary
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload",
          formData
        )
        .then((response) => {
          setServiceData({
            ...serviceData,
            image: response.data.secure_url,
          });
          toast.success("Image uploaded successfully");
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          toast.error("Error uploading image");
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !serviceData.name ||
      !serviceData.description ||
      !serviceData.price ||
      !serviceData.image
    ) {
      toast.error("Please fill all the fields, including uploading an image.");
      return;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/services/createServices`,
      headers: {
        "Content-Type": "application/json",
      },
      data: serviceData,
    };

    axios(config)
      .then((response) => {
        console.log("Service created successfully", response.data);
        toast.success("Service created successfully");
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        if (
          !serviceData.name ||
          !serviceData.description ||
          !serviceData.price
        ) {
          onError("Please fill all the fields");
        } else {
          onError("Error in creating service");
        }
      });
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60 p-16 backdrop-blur-sm backdrop-brightness-50"
      ref={modalRef}
      onClick={closeModal}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className=" hide-scrollbar relative flex max-h-[92%] flex-col gap-8 overflow-y-scroll rounded-lg border bg-white p-8 md:w-[50%] md:p-16"
        >
          <IoClose
            onClick={onClose}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide">
            Enter Service details
          </h1>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Service Name<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter Service Name"
              value={serviceData.name}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Description <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="description"
              placeholder="Add a description"
              value={serviceData.description}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Price<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={serviceData.price}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an image<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              autoComplete="off"
              onChange={handleImageChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          {serviceData.image &&
            (loading ? (
              <div className="w-36">
                <Loader className="max-h-fit" />
              </div>
            ) : (
              <div className="mt-4">
                <img
                  src={serviceData.image}
                  alt="Uploaded service image"
                  className="h-32 w-32 rounded-md object-cover"
                />
              </div>
            ))}
          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740] p-2 px-4 font-bold text-white"
          >
            Create service
          </button>
        </form>
      )}
    </div>
  );
}

export default ServiceModal;
