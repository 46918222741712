import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import Loader from "Components/Common/Loader/Loader";

function EditHeroSection({ onClose, showHeroSection }) {
  const modalRef = useRef();
  const { id } = useParams();
  const [heroSectionData, setHeroSectionData] = useState({
    heading: "",
    subheading: "",
    bgImg: "",
  });
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };
  const [loading, setLoading] = useState(false);

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setHeroSectionData(response.data.data?.[0]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("herops", heroSectionData);

  const handleChange = (e) => {
    setHeroSectionData({
      ...heroSectionData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    setLoading(true);

    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setHeroSectionData((prevData) => ({
        ...prevData,
        bgImg: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(heroSectionData);
    setLoading(true);

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/editHeroSection/${heroSectionData?._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: heroSectionData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setHeroSectionData(response?.data?.data);
        showHeroSection();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60  p-8 backdrop-blur-sm md:p-16 backdrop-brightness-50"
      ref={modalRef}
      onClick={closeModal}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="hide-scrollbar relative flex max-h-[95%] w-full flex-col gap-8 overflow-y-scroll rounded-lg border bg-white p-6 md:w-[80%] md:p-16 lg:w-[50%]"
        >
          <IoClose
            onClick={onClose}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Edit Hero Section details
          </h1>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Heading<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="heading"
              placeholder="Heading"
              autoComplete="off"
              value={heroSectionData?.heading}
              onChange={handleChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Description <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="subheading"
              placeholder="Add a description"
              autoComplete="off"
              value={heroSectionData?.subheading}
              onChange={handleChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80  shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an Image <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="file"
              name="bgImg"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          {heroSectionData?.bgImg && (
            <div className="mt-4">
              <img
                src={heroSectionData.bgImg}
                alt="Hero Section Image Uploaded"
                className="h-32 w-32 rounded-md object-cover"
              />
            </div>
          )}
          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default EditHeroSection;
