import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function EditOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [offerData, setofferData] = useState({
    name: "",
    description: "",
    image: "",
  });

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/getOfferById/${id}`,
      headers: {},
      data: offerData,
    };

    axios
      .request(config)
      .then((response) => {
        setofferData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setofferData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setofferData((prevData) => ({
        ...prevData,
        image: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/editOffer/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: offerData,
    };
    e.preventDefault();

    axios
      .request(config)
      .then((response) => {
        toast.success("Offer updated successfully");
        console.log(response.data.data);
      })
      .catch((error) => {
        console.error("Error in updating Offer:", error);
        toast.error("Failed to update Offer");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=" flex flex-col items-center justify-center lg:ml-72 xl:ml-auto ">
      <h1 className="mb-4 mt-8 text-3xl font-bold text-gray-900">
        Edit Offer Details
      </h1>
      <ToastContainer position="top-center" />

      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-navy-100 relative flex w-full flex-col gap-8 rounded-lg p-8 md:w-[80%] md:p-16"
        >
          {loading && <Loader />}
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Offer Name<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Offer Name"
              value={offerData.name}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Description<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="description"
              placeholder="Add a description"
              value={offerData.description}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an image<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
            {offerData.image && (
              <div className="mt-4">
                <img
                  src={offerData.image}
                  alt="Offer Image Uploaded"
                  className="h-32 w-32 rounded-md object-cover"
                />
              </div>
            )}
          </div>

          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
}

export default EditOffer;
