import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function Users() {
  const user = localStorage.getItem("User Details");
  const userObject = JSON.parse(user);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEditClick = (userId) => {
    setLoading(true);
    navigate(`/admin/users-profile/editUser/${userId}`);
    setLoading(false);
  };

  const showAllUsers = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/users/get-all-sub-admins`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setUsers(response.data.data);
        // getBranchByID();
        // console.log(users);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=> {
        setLoading(false);
      })
  };

  useEffect(() => {
    showAllUsers();
  }, []);

  const deleteUser = (id) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/users/delete-sub-admin-by-id/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("User deleted successfully");
        console.log(response.data);
        showAllUsers();
      })
      .catch((error) => {
        toast.error("Error in deleting branch");
        console.log(error);
      });
  };

  return (
    <div className="my-6 flex flex-col justify-end lg:ml-72 xl:ml-auto">
      <ToastContainer position="top-center" />
      {loading && <Loader />}
      <div
        className={`${
          userObject.userType === "admin"
            ? "flex items-end justify-end"
            : "hidden"
        }`}
      >
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-4 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={() => {
            navigate("/admin/user-registration");
          }}
        >
          Add New User
        </button>
      </div>

      {/* <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3">
        {users?.map((user, index) => (
          <div
            key={index}
            className="flex flex-col rounded-lg bg-white p-4 shadow-lg"
          >
            <h1 className="mb-2 text-2xl font-bold tracking-wide text-gray-800">
              {user?.name}
            </h1>
            <h2 className="text-base font-semibold tracking-wide text-gray-500">
              {user?.phoneNumber}
            </h2>
            
          </div>
        ))}
      </div> */}

      <div className="mt-6 overflow-x-auto">
        <table className="min-w-full table-auto border-collapse">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left">Name</th>
              <th className="border px-4 py-2 text-left">Contact</th>
              <th className="border px-4 py-2 text-left">Email</th>
              <th className="border px-4 py-2 text-left">Access</th>
              <th
                className={`${
                  userObject.userType === "admin"
                    ? "border px-4 py-2 text-left"
                    : "hidden"
                }`}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr key={index} className="mb-2 items-center border-b">
                <td className="px-4 py-2 ">{user?.name}</td>
                <td className="px-4 py-2">{user?.phoneNumber}</td>
                <td className="px-4 py-2">{user?.email}</td>
                <td className="px-4 py-2">{user?.branch?.name}</td>
                <td
                  className={`${
                    userObject.userType === "admin"
                      ? "px-4 py-2 text-left"
                      : "hidden"
                  }`}
                >
                  <div
                    className={`${
                      userObject.userType === "admin" ? "flex gap-2" : "hidden"
                    }`}
                  >
                    <FaRegEdit
                      className="cursor-pointer text-green-600"
                      onClick={() => handleEditClick(user?._id)}
                    />
                    <MdDeleteOutline
                      className="cursor-pointer text-red-800"
                      onClick={() => deleteUser(user?._id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Users;
