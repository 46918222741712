import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import Footer from "../Components/Footer/Footer";
import routes from "routes.js";
import AuthCheck from "Components/Common/AuthCheck/AuthCheck";

const Admin = (props) => {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      } else {
        const pathArray = window.location.pathname.split('/').filter(Boolean);
        if (routes[i]?.findPath === pathArray[1]) {
          for (let j = 0; j < routes[i]?.children?.length; j++) {
            if (routes[i]?.children[j]?.nestedFindPath === pathArray[2]) {
              setCurrentRoute(routes[i]?.children[j]?.name)
            }
          }
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.children) {
          return (
            <Route path={prop.path} element={prop.component} key={key}>
              {prop.children.map((child, childKey) => (
                <Route
                  path={child.path}
                  element={child.component}
                  key={`${key}-${childKey}`}
                />
              ))}
            </Route>
          );
        } else {
          if (prop.path) {
            return (
              <Route path={prop.path} element={prop.component} key={key} />
            );
          } else {
            return null;
          }
        }
      }
      return null;
    });
  };

  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  return (
    <>
      {isLoading ? (
        <AuthCheck setIsLoading={setIsLoading} layoutType="admin" />
      ) : (
        <div className="flex h-full w-full">
          <Sidebar open={open} onClose={() => setOpen(false)} />
          {/* Navbar & Main Content */}
          <div className="h-full w-full bg-[#fff1f6] dark:!bg-navy-900">
            {/* Main Content */}
            <main
              className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
            >
              {/* Routes */}
              <div className="h-full">
                <Navbar
                  onOpenSidenav={() => setOpen(true)}
                  logoText={"Horizon UI Tailwind React"}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(routes)}
                  {...rest}
                />
                <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                  <Routes>
                    {getRoutes(routes)}
                    <Route
                      path="/"
                      element={<Navigate to="/admin/dashboard" replace />}
                    />
                    {/* <Route path="*" element={<Navigate to="/404" />} /> */}
                  </Routes>
                </div>
                <div className="p-3">
                  <Footer />
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
};
export default Admin;
