import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function UserModal({ onClose }) {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    gender: "",
    nationality: "",
    password: "",
    branch: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    phoneNumber: "",
  });

  const [branches, setBranches] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userData.email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone number validation (assuming 10 digits)
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(userData.phoneNumber)) {
      valid = false;
      newErrors.phoneNumber = "Please enter a valid phone number (10 digits).";
    }

    setErrors(newErrors);
    return valid;
  };

  const fetchAllBranches = () => {
    const user = localStorage.getItem("User Details");
    const userObject = JSON.parse(user);
    const userID = userObject.id;
    console.log(userID);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/getallBranches/${userID}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log("response is printed", response.data.data);
        setBranches(response.data.data);
        console.log("branch is printed", branches);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllBranches();
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);
    if (!validateForm()) {
      // If form is invalid, return without submitting and show error messages
      toast.error("Please fill in the form correctly.", {
        hideProgressBar: true,
        draggable: true,
        pauseOnHover: true,
      });
      setLoading(false);

      return;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "${process.env.REACT_APP_API_KEY}/users/user-register",
      headers: {
        "Content-Type": "application/json",
      },
      data: userData,
    };

    e.preventDefault();

    axios
      .request(config)
      .then((response) => {
        console.log("User created successfully", response.data);
        toast.success("User created successfully", {
          hideProgressBar: true,
          draggable: true,
          pauseOnHover: true,
        });
        setUserData({
          name: "",
          email: "",
          phoneNumber: "",
          dateOfBirth: "",
          address: "",
          gender: "",
          nationality: "",
          password: "",
          branch: "",
        });
        return;
      })

      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }

        const emptyFields = Object.values(userData).some(
          (field) => field === ""
        );

        if (emptyFields) {
          toast.error("Please fill all the fields", {
            hideProgressBar: true,
            draggable: true,
            pauseOnHover: true,
          });
        } else {
          toast.error("Error in creating user", {
            hideProgressBar: true,
            draggable: true,
            pauseOnHover: true,
          });
        }
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(userData);

  return (
    <div className=" flex items-center justify-center p-4">
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className=" flex h-[90%] w-full max-w-4xl flex-col gap-6 rounded-lg md:p-8"
        >
          {/* <button className="absolute right-2 top-4">
          <IoClose className="" />
        </button> */}
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Enter User Details
          </h1>
          <div className="w-full">
            <label
              htmlFor="name"
              className="mb-2 block text-sm font-medium text-gray-700"
            >
              Name<span className="ml-1 text-red-500">*</span>
            </label>
            <input
              id="name" // Make sure the 'id' matches the 'for' attribute in the label
              name="name"
              type="text"
              placeholder="Enter User's name"
              value={userData.name}
              onChange={handleChange}
              autoComplete="off"
              className="w-full rounded-lg border  p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            <div className="w-full">
              <label
                htmlFor="email"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Email<span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Enter User's email"
                value={userData.email}
                onChange={handleChange}
                autoComplete="none"
                className="border-navy-300 w-full rounded-lg border p-4 opacity-80 shadow-lg focus:outline-[#fe8740] "
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="phoneNumber"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Phone No.<span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="phoneNumber" // Make sure the 'id' matches the 'for' attribute in the label
                name="phoneNumber"
                type="number"
                placeholder="Enter User's Phone number"
                value={userData.phoneNumber}
                onChange={handleChange}
                autoComplete="none"
                className="border-navy-300 w-full rounded-lg border p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between gap-4 md:flex-row">
            <div className="w-full md:w-1/2">
              <label
                htmlFor="dateOfBirth"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Date of Birth<span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="dateOfBirth"
                name="dateOfBirth"
                type="text"
                placeholder="Date of Birth"
                value={userData.dateOfBirth}
                onChange={handleChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => !e.target.value && (e.target.type = "text")}
                autoComplete="off"
                className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              />
            </div>

            <div className="w-full md:w-1/2">
              <label
                htmlFor="gender"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Gender<span className="ml-1 text-red-500">*</span>
              </label>
              <select
                id="gender" // Ensure the 'id' matches the 'for' attribute in the label
                name="gender"
                value={userData.gender}
                onChange={handleChange}
                autoComplete="off"
                className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              >
                <option value="" selected>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="address"
              className="mb-2 block text-sm font-medium text-gray-700"
            >
              Address<span className="ml-1 text-red-500">*</span>
            </label>
            <textarea
              id="address" // Make sure the 'id' matches the 'for' attribute in the label
              name="address"
              placeholder="Address"
              value={userData.address}
              onChange={handleChange}
              autoComplete="none"
              className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740] "
            />
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            {/* <input
            name="nationality"
            type="text"
            placeholder="Nationality"
            value={userData.nationality}
            onChange={handleChange}
            autoComplete="none"
            className="border-navy-300 w-full rounded-lg border bg-transparent p-4 opacity-80 shadow-lg placeholder:text-gray-800 focus:outline-none  md:w-1/2"
          /> */}

            <div className="w-full md:w-1/2">
              <label
                htmlFor="nationality"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Nationality<span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="nationality"
                name="nationality"
                placeholder="Nationality"
                value={userData.nationality}
                onChange={handleChange}
                autoComplete="off"
                className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              />
            </div>

            <div className="w-full md:w-1/2">
              <label
                htmlFor="branch"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                Branch<span className="ml-1 text-red-500">*</span>
              </label>
              <select
                id="branch" // Ensure the 'id' matches the 'for' attribute in the label
                name="branch"
                value={userData.branch}
                onChange={handleChange}
                autoComplete="off"
                className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              >
                <option value="" disabled>
                  Select Branch
                </option>
                {Array.isArray(branches) && branches.length > 0 ? (
                  branches.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No branches available
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-medium text-gray-700"
            >
              Password<span className="ml-1 text-red-500">*</span>
            </label>
            <input
              id="password" // Ensure the 'id' matches the 'for' attribute in the label
              name="password"
              type="password"
              placeholder="Password"
              value={userData.password}
              onChange={handleChange}
              autoComplete="off"
              className="border-navy-300 w-full rounded-lg border bg-gray-50 p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Create User
          </button>
        </form>
      )}
    </div>
  );
}

export default UserModal;
