import React, { useState } from "react";
import { useRef } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TeamModal({ onClose, id, showTeam }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [teamData, setTeamData] = useState({
    branch: id,
    name: "",
    role: "",
    profilePic: "",
  });

  const handleChange = (e) => {
    setTeamData({
      ...teamData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setTeamData((prevData) => ({
        ...prevData,
        profilePic: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
    }
  };

  const uploadToCloudinary = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(teamData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/addTeamMember`,
      headers: {
        "Content-Type": "application/json",
      },
      data: teamData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Team Member ---------> ", response)
        console.log("Team Member added successfully");
        showTeam();
        toast.success("Team Member added successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60 p-8 backdrop-brightness-50 backdrop-blur-sm md:p-16"
      ref={modalRef}
      onClick={closeModal}
    >
      <form
        onSubmit={handleSubmit}
        className="hide-scrollbar relative flex max-h-[95%] w-full flex-col gap-8 overflow-y-scroll rounded-lg border bg-white p-6 md:w-[80%] md:p-16 lg:w-[50%]"
      >
        <IoClose
          onClick={onClose}
          className="absolute right-4 top-4 cursor-pointer"
        />
        <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
          Add Team Member details
        </h1>

        <div>
          <label className="mb-2 block text-sm font-semibold">
            Heading<span className="ml-1 text-red-600">*</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            autoComplete="off"
            value={teamData.name}
            onChange={handleChange}
            className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
          />
        </div>

        <div>
          <label className="mb-2 block text-sm font-semibold">
            Role <span className="ml-1 text-red-600">*</span>
          </label>
          <input
            type="text"
            name="role"
            placeholder="Add Role"
            autoComplete="off"
            value={teamData.role}
            onChange={handleChange}
            className="w-full rounded-lg border bg-white p-4 opacity-80  shadow-lg focus:outline-[#fe8740]"
          />
        </div>
        <div>
          <label className="mb-2 block text-sm font-semibold">
            Choose an Image <span className="ml-1 text-red-600">*</span>
          </label>
          <input
            type="file"
            name="profilePic"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
          />
        </div>
        {teamData.profilePic && (
          <div className="mt-4">
            <img
              src={teamData.profilePic}
              alt="Hero Section Image Uploaded"
              className="h-32 w-32 rounded-md object-cover"
            />
          </div>
        )}
        <button
          type="submit"
          className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default TeamModal;
