import React, { useState } from "react";
import { useRef } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function BranchModal({ onClose, onError }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    city: "",
    phoneNo: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_KEY}/branch/createBranch`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formData,
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios(config)
      .then((response) => {
        console.log("Branch created successfully", response.data);
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        if (!formData.name || !formData.city || !formData.phoneNo) {
          onError("Please fill all the fields");
        } else {
          onError("Error in creating branch");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60 p-8 backdrop-blur-sm backdrop-brightness-50 md:p-16"
      ref={modalRef}
      onClick={closeModal}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className=" hide-scrollbar relative flex max-h-screen w-full flex-col gap-4 overflow-y-scroll rounded-lg border bg-white p-6 md:w-[80%] md:p-16 lg:w-[50%]"
        >
          {/* <button onClick={handleModalClose} className="absolute right-4 top-4">
          <IoClose className="text" />
        </button> */}
          <IoClose
            onClick={onClose}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Enter new branch details
          </h1>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Branch Name <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Branch Name"
              value={formData.name}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border-2 bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Branch Address <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="city"
              placeholder="Branch Address"
              value={formData.city}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Contact No <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="number"
              name="phoneNo"
              placeholder="Phone No."
              value={formData.phoneNo}
              autoComplete="off"
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Create Branch
          </button>
        </form>
      )}
    </div>
  );
}

export default BranchModal;
