import React, { useEffect, useState } from "react";
import Offer from "../Offer/Offer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Service from "../Service/Service";
import Plan from "../Plan/Plan";
import { useParams } from "react-router-dom";
import AddHeroSection from "./AddHeroSection";
import axios from "axios";
import HeroSection from "./HeroSection";
import Team from "../Team/Team";
import Gallery from "../Gallery/Gallery";
import Loader from "Components/Common/Loader/Loader";

function BranchDetails() {
  const { id } = useParams();
  const [section, setSection] = useState("offers");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const [branchData, setBranchData] = useState({
    name: "",
    city: "",
    phoneNo: "",
  });

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/getBranchById/${id}`,
      headers: {},
      data: branchData,
    };
    axios
      .request(config)
      .then((response) => {
        setBranchData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };
  useEffect(() => {
    fetchExistingData();
  }, [id]);

  return (
    <div className="my-6 flex flex-col lg:ml-72 xl:ml-auto">
      {loading && <Loader />}
      <h1 className=" mb-2 text-2xl font-bold ">
        Welcome to{" "}
        <span className="text-[#fe8740]">
          {branchData.name}, {branchData.city}
        </span>
      </h1>
      <p className="text-lg ">
        Contact:{" "}
        <span className="font-bold text-[#fe8740]">{branchData.phoneNo}</span>
      </p>
      <div className="flex flex-col justify-between md:flex-row">
        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "offers"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("offers")}
        >
          Offers
        </button>
        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "services"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("services")}
        >
          Services
        </button>

        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "plans"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("plans")}
        >
          Plans
        </button>

        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "team"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("team")}
        >
          Team
        </button>

        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "gallery"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("gallery")}
        >
          Gallery
        </button>

        <button
          className={`mt-4 w-40 rounded-lg  border border-[#fe8740] p-2 px-2 text-center font-bold ${
            section === "heroSection"
              ? "border-none bg-[#fe8740] text-white"
              : "text-[#fe8740] text-opacity-90"
          }  transition-all duration-100 ease-in-out hover:border-none hover:bg-[#fe8740] hover:text-white`}
          onClick={() => setSection("heroSection")}
        >
          Hero Section
        </button>
      </div>
      <div className="flex flex-col justify-end">
        <ToastContainer position="top-center" />
        <h1>{}</h1>
        {/* Offer Section */}
        {section === "offers" && <Offer />}

        {/* Service Section */}
        {section === "services" && <Service />}

        {/* Plan Section */}
        {section === "plans" && <Plan />}

        {/* Team Section */}
        {section === "team" && <Team />}

        {/* Gallery Section */}
        {section === "gallery" && <Gallery />}

        {/* Hero Section */}
        {section === "heroSection" && <HeroSection />}
      </div>

      {/* <div className="flex flex-col items-start">
        <h3 className="mb-2 mt-4 font-bold text-gray-800">Useful Links</h3>
        <button
          className="font-medium text-[#fe8740]"
          onClick={handleModalOpen}
        >
          Add Hero Section
        </button>
        <button className="font-medium text-[#fe8740]">
          Edit section BG images
        </button>
      </div>
      */}
    </div>
  );
}

export default BranchDetails;
