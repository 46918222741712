import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import { FaPlusSquare } from "react-icons/fa";
import Loader from "Components/Common/Loader/Loader";

function EditPlan() {
  const { id } = useParams();

  const [planData, setPlanData] = useState({
    type: "",
    price: "",
    keyPoints: [],
    image: "",
  });
  const [loading, setLoading] = useState(false);

  // Fetch existing plan data
  const fetchExistingData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_KEY}/plans/getPlanByPlanId/${id}`)
      .then((response) => {
        setPlanData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching plan data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  // Handle input change for plan fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle key point change
  const handleKeyPointChange = (index, value) => {
    const updatedKeyPoints = [...planData.keyPoints];
    updatedKeyPoints[index] = value;
    setPlanData((prevData) => ({ ...prevData, keyPoints: updatedKeyPoints }));
  };

  // Add a new key point
  const addKeyPoint = () => {
    setPlanData((prevData) => ({
      ...prevData,
      keyPoints: [...prevData.keyPoints, ""], // Add an empty string as a new key point
    }));
  };

  // Remove a key point
  const removeKeyPoint = (index) => {
    const updatedKeyPoints = planData.keyPoints.filter((_, i) => i !== index);
    setPlanData((prevData) => ({ ...prevData, keyPoints: updatedKeyPoints }));
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setPlanData((prevData) => ({
        ...prevData,
        image: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_API_KEY}/plans/editplans/${id}`, planData)
      .then(() => {
        toast.success("Plan updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating plan:", error);
        toast.error("Failed to update plan.");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center lg:ml-72 xl:ml-auto">
      <h1 className="mb-4 mt-8 text-3xl font-bold text-gray-900">
        Edit Plan Details
      </h1>
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="relative flex w-full flex-col gap-4 rounded-lg  p-8 md:w-[80%] md:p-16"
        >
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Plan Type<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="type"
              placeholder="Plan type"
              value={planData.type}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Price<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={planData.price}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Key Points<span className="ml-1 text-red-700">*</span>
            </label>
            {planData.keyPoints.map((keyPoint, index) => (
              <div key={index} className="mb-2 flex items-center gap-2">
                <input
                  type="text"
                  value={keyPoint}
                  onChange={(e) => handleKeyPointChange(index, e.target.value)}
                  className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
                />
                <IoClose
                  onClick={() => removeKeyPoint(index)}
                  className=" font-bold text-red-600"
                />
              </div>
            ))}
            <FaPlusSquare
              type="button"
              onClick={addKeyPoint}
              className="my-4 ml-2 text-blue-700"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an Image<span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>
          {planData.image && (
            <div className="mt-4">
              <img
                src={planData.image}
                alt="Offer Image Uploaded"
                className="h-32 w-32 rounded-md object-cover"
              />
            </div>
          )}
          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740] p-2 px-4 font-bold text-white"
          >
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
}

export default EditPlan;
