import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function EditBranch() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [branchData, setBranchData] = useState({
    name: "",
    city: "",
    phoneNo: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/getBranchById/${id}`,
      headers: {},
      data: branchData,
    };
    axios
      .request(config)
      .then((response) => {
        setBranchData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBranchData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    setLoading(true);
    let updateBranchConfig = (data) => ({
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/editBranch/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    e.preventDefault();

    axios
      .request(updateBranchConfig(branchData))
      .then((response) => {
        toast.success("Branch updated successfully");
        // navigate("/admin/branches"); // Navigate back to the branches page
      })
      .catch((error) => {
        console.error("Error updating branch:", error);
        toast.error("Failed to update branch");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=" flex flex-col items-center justify-center lg:ml-72 xl:ml-auto">
      <h1 className="mb-4 mt-8 text-3xl font-bold text-gray-900">
        Edit Branch Details
      </h1>
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-navy-100 relative flex w-full flex-col gap-8 rounded-lg p-8 md:w-[80%] md:p-16"
        >
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Branch Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Branch Name"
              value={branchData.name}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">Address</label>
            <input
              type="text"
              name="city"
              placeholder="Branch Address"
              value={branchData.city}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">Phone No</label>
            <input
              type="number"
              name="phoneNo"
              placeholder="Branch Phone No"
              value={branchData.phoneNo}
              onChange={handleInputChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
}

export default EditBranch;
