import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PlanModal from "./PlanModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";


import axios from "axios";
import Loader from "Components/Common/Loader/Loader";

function Plan() {
  const { id } = useParams(); //Branch ID fetched from URL
  const [open, setOpen] = useState(false);

  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //Function to open the Add New Plan modal
  const handleModalOpen = () => {
    setOpen(true);
  };

  //Function to close the Add New Plan modal
  const handleModalClose = () => {
    setOpen(false);
    showAllPlans();
  };

  const handleErrorToast = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      draggable: true,
      pauseOnHover: true,
    });
  };

  //Function to show all the plans on UI
  const showAllPlans = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setPlans(response.data);
        console.log(response.data);
        console.log(plans);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  //Function to navigate to the edit plan page
  const handleEditClick = (planID) => {
    navigate(`/admin/branches/branchDetails/editPlan/${planID}`);
  };

  //Function to delete a plan
  const deletePlan = (planID) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/plans/deletePlans/${planID}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Plan deleted successfully");
        console.log(response.data);
        showAllPlans();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showAllPlans();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div className="flex items-end justify-end">
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={handleModalOpen}
        >
          Add New Plan
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 xl:grid-cols-3">
        {plans?.map((plan, index) => (
          <div
            key={index}
            className="relative flex flex-col rounded-lg bg-white p-4 shadow-lg"
          >
            <div className="absolute right-2 top-2 flex gap-4 text-lg">
              <FaRegEdit
                className="cursor-pointer text-green-600"
                onClick={() => handleEditClick(plan._id)}
              />
              <MdDeleteOutline
                className="cursor-pointer text-red-800"
                onClick={() => deletePlan(plan._id)}
              />
            </div>
            <h1 className=" text-2xl font-bold tracking-wide text-gray-800 my-4">
              {plan.type}
            </h1>
            <h2 className="text-base font-semibold tracking-wide text-gray-700 mb-4">
              Price : {plan.price}/-
            </h2>
            <h2 className="font-semibold text-base mb-2">KeyPoints : </h2>
            <ul className="flex flex-col space-y-2">
              {plan.keyPoints.map((kp, index) => (
                <li key={index}>
                  {kp}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {open && (
        <PlanModal
          onClose={handleModalClose}
          onError={handleErrorToast}
          id={id}
        />
      )}
    </div>
  );
}

export default Plan;
