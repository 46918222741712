import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Loader from "Components/Common/Loader/Loader";

function Gallery() {
  const { id } = useParams();

  //State for individual gallery photo
  const [galleryData, setGalleryData] = useState({
    branch: id,
    gallery: "",
  });

  //State for loader
  const [loading, setLoading] = useState(false);

  //State for editing individual gallery photo
  const [editedGallery, setEditedGallery] = useState({
    gallery: "",
  });

  //State for all the gallery photo of a branch
  const [allGallery, setAllGallery] = useState([]);

  //Function for fetching all the gallery photo of a branch
  const showAllgalleryData = async () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setAllGallery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  console.log("Gallery----->", allGallery);

  useEffect(() => {
    showAllgalleryData();
  }, [id]);

  //Function to handle image change when uploading a photo
  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setGalleryData((prevData) => ({
        ...prevData,
        gallery: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
      handleSubmit(e, uploadedImageUrl);
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
    }
    finally{
      setLoading(false);
    }
  };

  //Function to upload the select photo to cloudinary and get a URL from it
  const uploadToCloudinary = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    }
  };

  //Function for submitting the selected photo that will save it in the database
  const handleSubmit = (e, uploadedImageUrl) => {
    e.preventDefault();
    console.log(galleryData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/uploadPhoto`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...galleryData, gallery: uploadedImageUrl },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Photo uploaded successfully");
        console.log("Response----->", response.data);
        showAllgalleryData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Funtion to editing a gallery photo
  const handleEdit = (e, editedImageURL, galleryId) => {
    e.preventDefault();
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/editGallery/${galleryId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...editedGallery, gallery: editedImageURL },
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Reponse: ", response.data);
        console.log("Gallery edited successfully");
        toast.success("Photo edited successfully");
        showAllgalleryData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Function to handle image change when editing a gallery photo
  const handleChange = async (e, galleryId) => {
    console.log("Gallery ID: ", galleryId);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const editedImageURL = await uploadToCloudinary(file);
      setEditedGallery((prevData) => ({
        ...prevData,
        gallery: editedImageURL,
      }));
      toast.success("Image uploaded successfully");
      handleEdit(e, editedImageURL, galleryId);
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
    }
  };

  //Function to delete a gallery photo
  const handleDelete = async (galleryId) => {
    console.log("Gallery ID: ", galleryId);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/deleteGallery/${galleryId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        toast.success("Photo deleted successfully");
        showAllgalleryData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
      {loading && <Loader />}
        <ToastContainer position="top-center" />
        <div className="flex items-end justify-end">
          <button
            className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
            onClick={() => document.getElementById("fileInput").click()}
          >
            Upload a photo
          </button>

          {/* Hidden file input field */}
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>

        <div className="my-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {allGallery.map((gallery, index) => (
            <div key={index} className="relative flex group ">
              <img src={gallery.gallery} alt="" className="object-cover w-full rounded-lg" />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>

              <div className="hidden absolute top-[50%] left-[50%] group-hover:flex gap-4">
                <FaRegEdit
                  className="cursor-pointer text-green-600 text-2xl"
                  onClick={() =>
                    document
                      .getElementById(`editFileInput-${gallery._id}`)
                      .click()
                  }
                />
                <MdDeleteOutline
                  className="cursor-pointer text-red-800 text-2xl"
                  onClick={() => handleDelete(gallery._id)}
                />
                {/* Hidden file input field */}
                <input
                  type="file"
                  name="gallery"
                  id={`editFileInput-${gallery._id}`}
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple
                  onChange={(e) => handleChange(e, gallery._id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
}

export default Gallery;
