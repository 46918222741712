import BranchModal from "./BranchModal";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "Components/Common/Loader/Loader";

function Branch() {
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [previousLength, setPreviousLength] = useState(0);
  const [hasShownToast, setHasShownToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    showAllBranches();
  };

  const handleErrorToast = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      draggable: true,
      pauseOnHover: true,
    });
  };

  //Funtion to navigate to Edit Branch page when clicked on the edit icon
  const handleEditClick = (branchId) => {
    navigate(`/admin/branches/editBranch/${branchId}`);
  };

  const user = localStorage.getItem("User Details");
  const userObject = JSON.parse(user);
  const userID = userObject.id;
  console.log(userID);

  //Function to show all the branches on UI
  const showAllBranches = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/getallBranches/${userID}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data.data);
        setBranches(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  useEffect(() => {
    showAllBranches();
  }, []);
  const deleteBranch = (id) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/deleteBranch/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Branch deleted successfully");
        console.log(response.data);
        showAllBranches();
      })
      .catch((error) => {
        toast.error("Error in deleting branch");
        console.log(error);
      });
  };

  useEffect(() => {
    setHasShownToast(false);
  });

  const navigateToDetailsPage = (branchId) => {
    navigate(`/admin/branches/branchDetails/${branchId}`);
  };

  console.log("Branch", branches);
  return (
    <div className="lg:ml-72 xl:ml-auto my-6 flex flex-col justify-end">
      {loading && <Loader />}
      <ToastContainer position="top-center" />
      {/* <p className="text-center text-lg">You don't have any branch created</p> */}
      {/* Branch cards go here */}
      <h1 className="mb-2 text-2xl font-bold ">
        All your <span className="text-[#fe8740]">Branches</span> are here
      </h1>
      <div
        className={`${
          userObject.userType === "admin"
            ? "flex items-end justify-end"
            : "hidden"
        }`}
      >
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-4 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={handleModalOpen}
        >
          Add New Branch
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 xl:grid-cols-3">
        {branches?.map((branch, index) => (
          <div
            key={index}
            className="relative flex flex-col rounded-lg bg-white p-4 shadow-lg"
          >
            <div className="absolute right-2 top-2 flex gap-4 text-lg">
              <MdOutlineRemoveRedEye
                className="cursor-pointer text-[#fe8740]"
                onClick={() => navigateToDetailsPage(branch._id)}
              />
              <FaRegEdit
                className="cursor-pointer text-green-600"
                onClick={() => handleEditClick(branch._id)}
              />
              {/* <MdDeleteOutline
                className="cursor-pointer text-red-800"
                onClick={() => deleteBranch(branch._id)}
              /> */}
            </div>
            <h1 className="my-4 text-2xl font-bold tracking-wide text-gray-800">
              {branch.name}
            </h1>
            <h2 className="text-base font-semibold tracking-wide text-gray-500">
              Address - {branch.city}
            </h2>
            <p className="text-base font-semibold tracking-wide text-gray-500">
              Contact - {branch.phoneNo}
            </p>
            <p className="text-base font-semibold tracking-wide text-gray-500">
              Managed By - {branch?.manager?.name}
            </p>
          </div>
        ))}
      </div>

      {open && (
        <BranchModal onClose={handleModalClose} onError={handleErrorToast} />
      )}
    </div>
  );
}

export default Branch;
