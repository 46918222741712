import { useState } from "react";
import React from "react";
import AddHeroSection from "./AddHeroSection";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import EditHeroSection from "./EditHeroSection";
import Loader from "Components/Common/Loader/Loader";

function HeroSection() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEditModalOpen = () => {
    setOpenEdit(true);
  };
  const handleEditModalClose = () => {
    setOpenEdit(false);
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  console.log("heroSectionData ===>", heroSectionData);

  const showHeroSection = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setHeroSectionData(response.data?.data);
        console.log("Hero Section Data: ", heroSectionData);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showHeroSection();
  }, []);

  return (
    <div className="flex flex-col">
      {loading && <Loader />}

      <div className="flex flex-col items-end justify-end gap-8">
        {heroSectionData?.length > 0 ? (
          <>
            <button
              className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
              onClick={handleEditModalOpen}
            >
              Edit Hero Section
            </button>
          </>
        ) : (
          <button
            className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
            onClick={handleModalOpen}
          >
            Add Hero Section
          </button>
        )}

        <div className="flex w-full flex-col gap-4">
          <h1 className="text-xl font-bold tracking-wide">
            Heading - {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-md font-semibold tracking-wide">
            Description - {heroSectionData?.[0]?.subheading}
          </p>
          <img
            src={heroSectionData?.[0]?.bgImg}
            alt="Hero Section Image"
            className=""
          />
        </div>
      </div>

      {open && (
        <AddHeroSection
          onClose={handleModalClose}
          showHeroSection={showHeroSection}
          // onError={handleErrorToast}
          id={id}
        />
      )}

      {openEdit && (
        <EditHeroSection
          onClose={handleEditModalClose}
          showHeroSection={showHeroSection}
          // onError={handleErrorToast}
          id={id}
        />
      )}
    </div>
  );
}

export default HeroSection;
