import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import TeamModal from "./TeamModal";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import EditTeam from "./EditTeam";
import Loader from "Components/Common/Loader/Loader";

function Team() {
  const { id } = useParams(); //Branch ID fetched from the URl
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [team, setTeam] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    showTeam();
  };

  const handleEditModalOpen = (id) => {
    setOpenEdit(true);
  };

  const handleEditModalClose = () => {
    setOpenEdit(false);
  };

  const handleEditClick = (memberId) => {
    navigate(`/admin/branches/branchDetails/editTeam/${memberId}`);
  };

  const deleteTeamMember = (memberId) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/deleteTeamMember/${memberId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Team Member deleted successfully");
        console.log(response.data);
        showTeam();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleErrorToast = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      draggable: true,
      pauseOnHover: true,
    });
  };

  //Function to show all the team members on UI
  const showTeam = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response --------->", response?.data);
        setTeam(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  console.log("Team--------->", team);

  return (
    <div>
      {loading && <Loader />}
      <div className="flex items-end justify-end ">
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={handleModalOpen}
        >
          Add Team Member
        </button>
      </div>

      <div className="relative py-12 text-center">
        <div className="mx-6 mt-5 grid grid-cols-1 gap-10 md:mx-16 md:grid-cols-2 lg:grid-cols-3 ">
          {team.map((member, index) => (
            <div
              key={index}
              className="transform rounded-lg bg-gradient-to-b from-yellow-500 to-transparent p-4 shadow-lg transition-transform duration-300 hover:scale-105"
            >
              <div className="absolute right-2 top-2 flex gap-4 text-lg">
                {/* <MdOutlineRemoveRedEye
                    className="cursor-pointer text-[#fe8740]"
                    onClick={() => navigateToDetailsPage(branch._id)}
                  /> */}
                <FaRegEdit
                  className="cursor-pointer text-green-600"
                  onClick={() => handleEditClick(member._id)}
                />
                <MdDeleteOutline
                  className="cursor-pointer text-red-800"
                  onClick={() => deleteTeamMember(member._id)}
                />
              </div>
              <img
                src={member.profilePic}
                alt={`Portrait of ${member.name}`}
                className="my-4 h-[20rem] w-full rounded-lg object-cover"
              />
              <h3 className="font-playfair-display text-xl font-bold">
                {member.name}
              </h3>
              <p className="text-gray-500">{member.role}</p>
            </div>
          ))}
        </div>
      </div>

      {open && (
        <TeamModal onClose={handleModalClose} showTeam={showTeam} id={id} />
      )}
    </div>
  );
}

export default Team;
