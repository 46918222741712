import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceModal from "../Service/ServiceModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import axios from "axios";
import Loader from "Components/Common/Loader/Loader";

function Service() {
  const { id } = useParams(); //Branch ID fetched from URL
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  //Function to open the Add New Branch modal
  const handleModalOpen = () => {
    setOpen(true);
  };

  //Function to close the Add New Branch modal
  const handleModalClose = () => {
    setOpen(false);
    showAllServices();
  };

  const handleErrorToast = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      draggable: true,
      pauseOnHover: true,
    });
  };

  //Function to show all the services on UI
  const showAllServices = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/services/getAllServiceByBranchId/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setServices(response.data);
        console.log(response.data);
        console.log(services);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  //Function to navigate to the edit offer page
  const handleEditClick = (serviceID) => {
    navigate(`/admin/branches/branchDetails/editService/${serviceID}`);
  };

  //Function to delete an offer
  const deleteService = (serviceID) => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/services/deleteService/${serviceID}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Service deleted successfully");
        console.log(response.data.data);
        showAllServices();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showAllServices();
  }, []);

  return (
    
    <div>
      {loading && <Loader />}
      <div className="flex items-end justify-end">
        <button
          className="mt-4 w-40 rounded-lg bg-[#fe8740] p-2 px-2 text-center font-bold text-white text-opacity-90 hover:bg-[#ed4609]"
          onClick={handleModalOpen}
        >
          Add New Service
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 xl:grid-cols-3">
        {services?.map((service, index) => (
          <div
            key={index}
            className="relative flex flex-col rounded-lg bg-white p-4 shadow-lg"
          >
            <div className="absolute right-2 top-2 flex gap-4 text-lg">
              {/* <MdOutlineRemoveRedEye
                    className="cursor-pointer text-[#fe8740]"
                    onClick={() => navigateToDetailsPage(branch._id)}
                  /> */}
              <FaRegEdit
                className="cursor-pointer text-green-600"
                onClick={() => handleEditClick(service._id)}
              />
              <MdDeleteOutline
                className="cursor-pointer text-red-800"
                onClick={() => deleteService(service._id)}
              />
            </div>
            <h1 className="mb-2 text-2xl font-bold tracking-wide text-gray-800 my-4">
              {service.name}
            </h1>
            <h2 className="text-base font-semibold tracking-wide text-gray-500 mb-2">
              {service.description}
            </h2>
            <h2 className="text-base font-semibold tracking-wide text-gray-500">
             Price - {service.price}/-
            </h2>
            {/* <p className="text-base font-semibold tracking-wide text-gray-500">
                  Manager's Name
                </p> */}
          </div>
        ))}
      </div>

      {open && (
        <ServiceModal
          onClose={handleModalClose}
          onError={handleErrorToast}
          id={id}
        />
      )}
      
    </div>
  );
}

export default Service;
