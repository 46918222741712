import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import Loader from "Components/Common/Loader/Loader";

function EditTeam({ onClose, showTeam }) {
  //   const modalRef = useRef();
  const { id } = useParams();

  const [teamData, setTeamData] = useState({
    name: "",
    role: "",
    profilePic: "",
  });
  const [loading, setLoading] = useState(false);

  //   const closeModal = (e) => {
  //     if (modalRef.current === e.target) {
  //       onClose();
  //     }
  //   };

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamMemberById/${id}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setTeamData(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  console.log("Team Data -------> ", teamData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setTeamData((prevData) => ({
        ...prevData,
        profilePic: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    setLoading(false);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/editTeamMember/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: teamData,
    };
    e.preventDefault();
    axios
      .request(config)
      .then((response) => {
        toast.success("Team Member updated successfully");
        console.log(response.data.data);
      })
      .catch((error) => {
        console.error("Error in updating Team member:", error);
        toast.error("Failed to update Team Member");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className=" flex flex-col items-center justify-center lg:ml-72 xl:ml-auto ">
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="hide-scrollbar relative mt-8 flex max-h-[95%] w-full flex-col gap-8 overflow-y-scroll rounded-lg border bg-white p-6 md:w-[70%] md:p-16 "
        >
          {/* <IoClose
          onClick={onClose}
          className="absolute right-4 top-4 cursor-pointer"
        /> */}
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Edit Team Member details
          </h1>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Name<span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              autoComplete="off"
              value={teamData?.name}
              onChange={handleChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Role <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="role"
              placeholder="Add Role"
              autoComplete="off"
              value={teamData?.role}
              onChange={handleChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80  shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an Image <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="file"
              name="bgImg"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          {teamData?.profilePic && (
            <div className="mt-4">
              <img
                src={teamData?.profilePic}
                alt="Team Member Image Uploaded"
                className="h-32 w-32 rounded-md object-cover"
              />
            </div>
          )}
          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default EditTeam;
