import React, { useState } from "react";
import { useRef } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function OfferModal({ onClose, onError, id }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [offerData, setofferData] = useState({
    name: "",
    description: "",
    branch: id,
    image: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setofferData({
      ...offerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setofferData((prevData) => ({
        ...prevData,
        image: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(offerData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/createOffer`,
      headers: {
        "Content-Type": "application/json",
      },
      data: offerData,
    };

    axios(config)
      .then((response) => {
        console.log("Offer created successfully", response.data);
        toast.success("Offer created successfully");
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        if (!offerData.name || !offerData.description) {
          onError("Please fill all the fields");
        } else {
          onError("Error in creating Offer");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60 p-8 backdrop-blur-sm backdrop-brightness-50 md:p-16"
      ref={modalRef}
      onClick={closeModal}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="hide-scrollbar relative flex max-h-[95%] w-full flex-col gap-8 overflow-y-scroll rounded-lg border bg-white p-6 md:w-[80%] md:p-16 lg:w-[50%] "
        >
          <IoClose
            onClick={onClose}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Enter Offer details
          </h1>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Offer Name <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Offer Name"
              autoComplete="off"
              value={offerData.name}
              onChange={handleChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Offer Description <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="text"
              name="description"
              placeholder="Add a description"
              autoComplete="off"
              value={offerData.description}
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80  shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an Image <span className="ml-1 text-red-600">*</span>
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          {offerData.image && (
            <div className="mt-4">
              <img
                src={offerData.image}
                alt="Offer Image Uploaded"
                className="h-32 w-32 rounded-md object-cover"
              />
            </div>
          )}
          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Create Offer
          </button>
        </form>
      )}
    </div>
  );
}

export default OfferModal;
