import React from "react";
import MainDashboard from "./Screens/Admin/Dashboard/Dashboard";
import SignIn from "./Screens/Auth/SignIn";

import {
  MdHome,
  MdOutlineShoppingCart,
  MdLock,
  MdCake,
} from "react-icons/md";
import { EngagedUser } from "Screens/Admin/Traffic/EngagedUser";
import { IdleUser } from "Screens/Admin/Traffic/IdleUser";
import User from './Screens/Admin/User/Users';
import UserProfile from "Screens/Admin/Common/UserProfile";
import Branch from "Screens/Admin/Branch/Branch";
// import { layout } from "@chakra-ui/system";
import UserModal from "Screens/Admin/User/UserModal";
import BranchDetails from "Screens/Admin/Branch/BranchDetails";
import EditBranch from "Screens/Admin/Branch/EditBranch";
import EditUser from "Screens/Admin/User/EditUser";
import EditOffer from "./Screens/Admin/Offer/EditOffer";
import EditService from "Screens/Admin/Service/EditService";
import EditPlan from "Screens/Admin/Plan/EditPlan";
import EditTeam from "Screens/Admin/Team/EditTeam";

const routes = [
  {
    name: "Dashboard",
    showInSidebar: false,
    showToSubAdmin: true,
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    clickable: true,
  },
  {
    name: "Traffic",
    showInSidebar: false,
    showToSubAdmin: true,
    layout: "/admin",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    clickable: false,
    children: [
      {
        name: "Engaged User",
        layout: "/admin",
        path: "traffic/engaged-user",
        component: <EngagedUser />,
      },
      {
        name: "Idle User",
        layout: "/admin",
        path: "traffic/idle-user",
        component: <IdleUser />,
      },
    ],
  },
  {
    name: "Branch ",
    showInSidebar: true,
    showToSubAdmin: true,
    layout: "/admin",
    path: "branches",
    icon: <MdCake className="h-6 w-6" />,
    component: <Branch />,
    clickable: true,
  },
  {
    name: "Branch Details",
    showInSidebar: false,
    showToSubAdmin: true,
    layout: "/admin",
    path: "/branches/branchDetails/:id",
    component: <BranchDetails />
  },
  {
    name: "Edit Branch",
    showInSidebar: false,
    layout: "/admin",
    path: "/branches/editBranch/:id",
    component: <EditBranch />
  },

  {
    name: "User Registration",
    showInSidebar: false,
    showToSubAdmin: false, 
    layout: "/admin",
    path:"user-registration",
    icon: <MdCake className="h-6 w-6"/>,
    component: <UserModal />
  },
  {
    name: "Users Profile",
    showInSidebar: true,
    showToSubAdmin: true,
    layout: "/admin",
    path: "users-profile",
    showToSubAdmin: true, 
    icon: <MdCake className="h-6 w-6" />,
    component: <User />,
    clickable: true,
  },
  {
    name: "Edit User",
    showInSidebar: false,
    layout: "/admin",
    path: "/users-profile/editUser/:id",
    component: <EditUser />,
  },

  {
    name:"Edit Offer",
    showInSidebar: false,
    layout:"/admin",
    path: "/branches/branchDetails/editOffer/:id",
    component: <EditOffer />
  },

  {
    name:"Edit Service",
    showInSidebar: false,
    layout:"/admin",
    path: "/branches/branchDetails/editService/:id",
    component: <EditService />
  },

  {
    name:"Edit Plan",
    showInSidebar: false,
    layout:"/admin",
    path: "/branches/branchDetails/editPlan/:id",
    component: <EditPlan />
  },

  {
    name:"Edit Team",
    showInSidebar: false,
    layout:"/admin",
    path: "/branches/branchDetails/editTeam/:id",
    component: <EditTeam />
  },



  
  // Auth Routes Below
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];

export default routes;
