import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const AuthCheck = ({ setIsLoading, layoutType }) => {
  let navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("Token");
    setTimeout(() => {
      if (layoutType === "admin" && !token) {
        navigate("/auth/sign-in");
      } else if (layoutType === "auth" && token) {
        navigate("/admin/branches");
      } else if (layoutType === "initialRender") {
        if (!token) {
          navigate("/auth/sign-in");
        } else {
          navigate("/admin/branches");
        }
      } else {
        setIsLoading(false);
      }
    }, 1000);
  }, [layoutType, navigate, setIsLoading]);
  return <Loader />;
};
export default AuthCheck;
