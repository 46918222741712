import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "Components/Common/Loader/Loader";

function EditService() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serviceData, setserviceData] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchExistingData = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/services/getServiceByServiceId/${id}`,
      headers: {},
      data: serviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setserviceData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  const handleInputChange = (e) => {
    setLoading(true);
    const { name, value } = e.target;
    setserviceData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setserviceData((prevData) => ({
        ...prevData,
        image: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/services/editService/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: serviceData,
    };
    e.preventDefault();

    axios
      .request(config)
      .then((response) => {
        toast.success("service updated successfully");
        console.log(response.data.data);
      })
      .catch((error) => {
        console.error("Error in updating service:", error);
        toast.error("Failed to update service");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="  lg:ml-72 xl:ml-auto xl:px-16">
      {loading ? (
        <Loader />
      ) : (
        <div className=" flex flex-col items-center justify-center">
          <h1 className="mb-4 mt-8 text-3xl font-bold text-gray-900">
            Edit Service Details
          </h1>
          <ToastContainer position="top-center" />
          <form
            onSubmit={handleSubmit}
            className="relative flex w-full flex-col gap-8 rounded-lg p-8 md:w-[80%] md:p-16"
          >

            <div>
              <label className="mb-2 block text-sm font-semibold">
                Service Name<span className="ml-1 text-red-700">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Service Name"
                value={serviceData.name}
                onChange={handleInputChange}
                className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
              />
            </div>

            <div>
              <label className="mb-2 block text-sm font-semibold">
                Description<span className="ml-1 text-red-700">*</span>
              </label>
              <input
                type="text"
                name="description"
                placeholder="Add a description"
                value={serviceData.description}
                onChange={handleInputChange}
                className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
              />
            </div>

            <div>
              <label className="mb-2 block text-sm font-semibold">
                Price<span className="ml-1 text-red-700">*</span>
              </label>
              <input
                type="number"
                name="price"
                placeholder="Price"
                value={serviceData.price}
                onChange={handleInputChange}
                className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
              />
            </div>

            <div>
              <label className="mb-2 block text-sm font-semibold">
                Choose an image<span className="ml-1 text-red-700">*</span>
              </label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
              />
              {serviceData.image && (
                <div className="mt-4">
                  <img
                    src={serviceData.image}
                    alt="Offer Image Uploaded"
                    className="h-32 w-32 rounded-md object-cover"
                  />
                </div>
              )}
            </div>

            <button
              type="submit"
              className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
            >
              Save Changes
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default EditService;
