import React, { useState } from "react";
import { useRef } from "react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlusSquare } from "react-icons/fa";
import Loader from "Components/Common/Loader/Loader";

function PlanModal({ onClose, onError, id }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const [planData, setPlanData] = useState({
    type: "",
    branch: id,
    price: "",
    keyPoints: [],
    image: "",
  });
  const [loading, setLoading] = useState(false);

  const [keyPoint, setKeyPoint] = useState(""); // State for individual keyPoint

  const handleAddKeyPoint = () => {
    if (keyPoint.trim() !== "") {
      setPlanData((prevData) => ({
        ...prevData,
        keyPoints: [...prevData.keyPoints, keyPoint.trim()],
      }));
      setKeyPoint("");
    }
  };

  const handleRemoveKeyPoint = (index) => {
    setPlanData((prevData) => ({
      ...prevData,
      keyPoints: prevData.keyPoints.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (e) => {
    setPlanData({
      ...planData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image file");
      return;
    }

    try {
      const uploadedImageUrl = await uploadToCloudinary(file);
      setPlanData((prevData) => ({
        ...prevData,
        image: uploadedImageUrl,
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload failed", error);
      toast.error("Image upload failed");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const uploadToCloudinary = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "wjs1zyo5");

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dnw1ttr02/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      toast.error("Failed to upload image");
      throw error;
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !planData.type.trim() ||
      !planData.price.trim() ||
      planData.keyPoints.length === 0
    ) {
      toast.error(
        "Please fill all the fields, including at least one key point."
      );
      return;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/plans/createPlans`,
      headers: {
        "Content-Type": "application/json",
      },
      data: planData,
    };

    axios(config)
      .then((response) => {
        console.log("Plan created successfully", response.data);
        toast.success("Plan created successfully");
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        toast.error("Error in creating plan");
      });
  };

  console.log(planData.keyPoints);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-60  p-16 backdrop-blur-sm backdrop-brightness-50"
      ref={modalRef}
      onClick={closeModal}
    >
      {loading ? (
        <Loader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="hide-scrollbar relative flex max-h-[92%] flex-col gap-4 overflow-y-scroll rounded-lg bg-white p-8 md:w-[50%] md:p-16"
        >
          <IoClose
            onClick={onClose}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h1 className="text-navy-700 text-xl font-semibold tracking-wide ">
            Enter Plan details
          </h1>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Plan Type <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="text"
              name="type"
              placeholder="Plan Type"
              autoComplete="off"
              value={planData.type}
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>
          <div>
            <label className="mb-2 block text-sm font-semibold">
              Price <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="number"
              name="price"
              placeholder="Price"
              autoComplete="off"
              value={planData.price}
              onChange={handleChange}
              className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-semibold">
              Choose an image <span className="ml-1 text-red-700">*</span>
            </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="border-navy-300 rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-none"
            />
          </div>

          {planData.image && (
            <div className="mt-4">
              <img
                src={planData.image}
                alt="Plam Image Uploaded"
                className="h-32 w-32 rounded-md object-cover"
              />
            </div>
          )}

          <div className="flex items-center gap-2">
            <div className="w-full">
              <label className="mb-2 block text-sm font-semibold">
                Keypoints <span className="ml-1 text-red-700">*</span>
              </label>
              <input
                type="text"
                name="keyPoint"
                placeholder="Enter a keypoint"
                autoComplete="off"
                value={keyPoint}
                onChange={(e) => setKeyPoint(e.target.value)}
                className="border-navy-300 w-full rounded-lg border bg-white p-4 opacity-80 shadow-lg focus:outline-[#fe8740]"
              />
            </div>
            <FaPlusSquare
              className="-mr-6 h-full cursor-pointer text-blue-700"
              onClick={handleAddKeyPoint}
            />
          </div>
          <ul className="flex space-x-4">
            {planData.keyPoints.map((point, index) => (
              <li
                key={index}
                className="flex h-6 items-center justify-between rounded-lg bg-gray-200 p-2"
              >
                <span>{point}</span>
                <IoClose
                  className="ml-2 cursor-pointer text-red-600"
                  onClick={() => handleRemoveKeyPoint(index)}
                />
              </li>
            ))}
          </ul>

          <button
            type="submit"
            className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
          >
            Create Plan
          </button>
        </form>
      )}
    </div>
  );
}

export default PlanModal;
