import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditUser() {
  const { id } = useParams();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    gender: "",
    nationality: "",
    branch: "",
  });
  const [branches, setBranches] = useState();

  const fetchExistingData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/users/get-sub-admin-by-id/${id}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        setUserData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchExistingData();
  }, [id]);

  const fetchAllBranches = () => {
    const user = localStorage.getItem("User Details");
    const userObject = JSON.parse(user);
    const userID = userObject.id;
    console.log(userID);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/branch/getallBranches/${userID}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.log("response is printed", response.data.data);
        setBranches(response.data.data);
        console.log("branch is printed", branches);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAllBranches();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    const {
      _id,
      __v,
      permissions,
      profilePic,
      userType,
      isDeleted,
      password,
      ...allowedData
    } = userData;
    console.log("allowed Data: ", allowedData);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/users/user-edit-profile/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: allowedData,
    };
    e.preventDefault();

    axios
      .request(config)
      .then((response) => {
        toast.success("User Details updated successfully");
        console.log(response.data);
        // console.log("allowed data: ",allowedData)
      })
      .catch((error) => {
        if (error.response) {
          console.log("Response Error", error.response.data);
        } else if (error.request) {
          console.log("Request Error", error.request);
        } else {
          console.log("Axios Error", error.message);
        }
        toast.error("Failed to update User details");
      });
  };

  return (
    <div className=" flex items-center justify-center p-4">
      <ToastContainer position="top-center" />
      <form
        onSubmit={handleSubmit}
        className=" flex h-[90%] w-full max-w-3xl flex-col gap-8 rounded-lg bg-navy-100 p-8"
      >
        <h1 className="text-xl font-semibold tracking-wide text-navy-700 ">
          Edit User Details
        </h1>
        <div>
          <label className="mb- block text-sm font-semibold">Name</label>
          <input
            name="name"
            type="text"
            placeholder="Name"
            value={userData.name}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none"
          />
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={userData.email}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          />

          <input
            name="phoneNumber"
            type="number"
            placeholder="Phone No."
            value={userData.phoneNumber}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          />
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <input
            name="dateOfBirth"
            type="text"
            placeholder="Date of Birth"
            value={userData.dateOfBirth}
            onChange={handleInputChange}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => !e.target.value && (e.target.type = "text")}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          />
          <input
            name="address"
            type="text"
            placeholder="Address"
            value={userData.address}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          />
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <select
            name="gender"
            value={userData.gender}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          >
            <option value="" selected>
              Select Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <input
            name="nationality"
            type="text"
            placeholder="Nationality"
            value={userData.nationality}
            onChange={handleInputChange}
            className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
          />
        </div>
        <select
          name="branch"
          value={userData.branch}
          onChange={handleInputChange}
          className="w-full rounded-lg border border-navy-300 bg-white p-4 opacity-80 shadow-lg focus:outline-none md:w-1/2"
        >
          <option value="" selected>
            Select Branch
          </option>
          {Array.isArray(branches) && branches.length > 0 ? (
              branches.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No branches available
              </option>
            )}
        </select>

        <button
          type="submit"
          className="w-36 rounded-lg bg-[#fe8740]  p-2 px-4 font-bold text-white"
        >
          Save changes
        </button>
      </form>
    </div>
  );
}

export default EditUser;
